import React, { useEffect } from 'react'
import { Navbar } from '../features/navigation/components/Navbar'
import { ProductList } from '../features/products/components/ProductList'
import { resetAddressStatus, selectAddressStatus } from '../features/address/AddressSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../features/footer/Footer'
import { Helmet } from 'react-helmet'

export const HomePage = () => {

  const dispatch = useDispatch()
  const addressStatus = useSelector(selectAddressStatus)

  useEffect(() => {
    if (addressStatus === 'fulfilled') {

      dispatch(resetAddressStatus())
    }
  }, [addressStatus])

  return (
    <>
      <Helmet>
        <title>Home Page | My Awesome Site</title>
        <meta name="description" content="This is the home page of my awesome website." />
        <meta name="keywords" content="home, awesome site, react helmet" />
        <meta property="og:title" content="Home Page | My Awesome Site" />
        <meta property="og:description" content="Visit the home page of this fantastic site." />
        <meta property="og:url" content="https://www.myawesomesite.com" />
      </Helmet>
      <Navbar isProductList={true} />
      <ProductList />
      <Footer />
    </>
  )
}
