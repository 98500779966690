// import * as React from 'react';
// import { AppBar, Toolbar, IconButton, Typography, Avatar, Menu, MenuItem, Badge, Stack, Button, useMediaQuery, useTheme, Fab } from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

// import TuneIcon from '@mui/icons-material/Tune';
// import { useEffect, useState } from 'react';
// import { selectUserInfo } from '../../user/UserSlice';
// import { selectCartItems } from '../../cart/CartSlice';
// import { selectLoggedInUser } from '../../auth/AuthSlice';
// import { selectWishlistItems } from '../../wishlist/WishlistSlice';
// import { selectProductIsFilterOpen, toggleFilters } from '../../products/ProductSlice';

// export const Navbar = ({ isProductList = false }) => {
//   const userInfo = useSelector(selectUserInfo);
//   const cartItems = useSelector(selectCartItems);
//   const loggedInUser = useSelector(selectLoggedInUser);
//   const wishlistItems = useSelector(selectWishlistItems);
//   const isProductFilterOpen = useSelector(selectProductIsFilterOpen);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [anchorElUser, setAnchorElUser] = useState(null);

//   const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
//   const handleCloseUserMenu = () => setAnchorElUser(null);
//   const handleToggleFilters = () => dispatch(toggleFilters());

//   useEffect(() => {
//     console.log(userInfo);
//   }, [userInfo]);

//   const settings = [
//     { name: "Home", to: "/" },
//     { name: 'Profile', to: loggedInUser?.isAdmin ? "/admin/profile" : "/profile" },
//     { name: loggedInUser?.isAdmin ? 'Orders' : 'My orders', to: loggedInUser?.isAdmin ? "/admin/orders" : "/orders" },
//     { name: 'Logout', to: "/logout" },
//   ];

//   return (
//     <AppBar position="sticky" sx={{ backgroundColor: 'white', boxShadow: 'none', color: 'text.primary' }}>
//       <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
//         {/* Logo */}
//         <Typography variant="h6" component={Link} to="/" sx={{ display: { xs: 'none', sm: 'block' }, textDecoration: 'none' }}>
//           <img src="/images/logo.png" alt="Logo" style={{ height: '50px' }} />
//         </Typography>

//         {/* User Avatar and Menu */}
//         <Stack direction="row" alignItems="center" spacing={2}>
//           <IconButton onClick={handleOpenUserMenu}>
//             <Avatar alt={userInfo?.name} src={userInfo?.avatar} />
//           </IconButton>
//           <Menu anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
//             {settings.map((setting) => (
//               <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
//                 <Typography component={Link} to={setting.to} sx={{ textDecoration: 'none', color: 'inherit' }}>{setting.name}</Typography>
//               </MenuItem>
//             ))}
//           </Menu>
//           <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'block' } }}>
//             {userInfo ? `Hi, ${userInfo.name}` : "Welcome"}
//           </Typography>
//           {!loggedInUser && <Button variant="contained" onClick={() => navigate("/login")}>Login</Button>}
//         </Stack>

//         {/* Cart and Wishlist Icons */}
//         <Stack direction="row" alignItems="center" spacing={3}>
//           <Badge badgeContent={cartItems?.length || 0} color="error">
//             <IconButton component={Link} to="/cart">
//               <ShoppingCartOutlinedIcon />
//             </IconButton>
//           </Badge>
//           <Badge badgeContent={wishlistItems?.length || 0} color="error">
//             <IconButton component={Link} to="/wishlist">
//               <FavoriteBorderIcon />
//             </IconButton>
//           </Badge>
//           {isProductList && (
//             <IconButton onClick={handleToggleFilters}>
//               <TuneIcon color={isProductFilterOpen ? "primary" : "inherit"} />
//             </IconButton>
//           )}
//         </Stack>
//       </Toolbar>
//     </AppBar>
//   );
// };



//  search feature 


import * as React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Avatar, Menu, MenuItem, Badge, Stack, Button, useMediaQuery, useTheme, TextField, InputAdornment, List, ListItem, ListItemButton, ListItemText, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { useEffect, useState } from 'react';
import { selectUserInfo } from '../../user/UserSlice';
import { selectCartItems } from '../../cart/CartSlice';
import { selectLoggedInUser } from '../../auth/AuthSlice';
import { selectWishlistItems } from '../../wishlist/WishlistSlice';
import { selectProductIsFilterOpen, selectProducts, toggleFilters } from '../../products/ProductSlice';
import { toast } from 'react-toastify';

export const Navbar = ({ isProductList = false }) => {
  const userInfo = useSelector(selectUserInfo);

  const cartItems = useSelector(selectCartItems);
  const loggedInUser = useSelector(selectLoggedInUser);
  const wishlistItems = useSelector(selectWishlistItems);
  const isProductFilterOpen = useSelector(selectProductIsFilterOpen);
  const products = useSelector(selectProducts)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const handleToggleFilters = () => dispatch(toggleFilters());


  useEffect(() => {
    console.log(products);
  }, [products])

  // Fetch suggestions (this could be from an API or local dataset)
  const fetchSuggestions = async (query) => {
    if (query.trim()) {
      // Filter based on relevant fields (e.g., title or description)
      const filteredSuggestions = products.filter(item =>
        item.title.toLowerCase().includes(query.toLowerCase()) ||
        item.description.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };
  // Handler for search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    fetchSuggestions(e.target.value);
  };

  // Handle form submission (if needed)
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log(searchQuery);
    toast.error('select the given suggesstion')
    // if (searchQuery.trim()) {
    //   navigate(`/search?query=${searchQuery}`);
    setSuggestions([]);  // Clear suggestions on submit
    // }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    console.log(suggestion);
    navigate(`/product-details/${suggestion._id}`)
    // setSearchQuery(suggestion);
    // navigate(`/search?query=${suggestion}`);
    setSuggestions([]);  // Clear suggestions after selection
  };

  const settings = [
    { name: "Home", to: "/" },
    { name: 'Profile', to: loggedInUser?.isAdmin ? "/admin/profile" : "/profile" },
    // Conditionally add an item to the array
    { name: loggedInUser?.isAdmin ? 'Add Product' : null, to: loggedInUser?.isAdmin ? "/admin/add-product" : null },
    { name: loggedInUser?.isAdmin ? 'Orders' : 'My Orders', to: loggedInUser?.isAdmin ? "/admin/orders" : "/orders" },
    { name: 'Logout', to: "/logout" },
  ];


  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'white', boxShadow: 'none', color: 'text.primary' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
        {/* Logo */}
        <Typography variant="h6" component={Link} to="/" sx={{ display: { xs: 'none', sm: 'block' }, textDecoration: 'none' }}>
          <img src="/images/logo.png" alt="Logo" style={{ height: '80px' }} />
        </Typography>

        {/* Search Bar */}
        <form onSubmit={handleSearchSubmit} style={{ flex: 1, margin: '0 1rem', position: 'relative' }}>
          <TextField
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search products..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          />
          {/* Suggestions Dropdown */}
          {suggestions.length > 0 && (
            <Paper elevation={3} style={{ position: 'absolute', top: '100%', width: '100%', zIndex: 10 }}>
              <List>
                {suggestions.map((suggestion, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton onClick={() => handleSuggestionClick(suggestion)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      {/* Render title and price, ensuring they're aligned properly */}
                      <ListItemText primary={suggestion.title} />
                      <ListItemText primary={`₹${suggestion.price}`} style={{ textAlign: 'right' }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}

        </form>

        {/* User Avatar and Menu */}
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={handleOpenUserMenu}>
            <Avatar alt={userInfo?.name} src={userInfo?.avatar} />
          </IconButton>
          <Menu anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
            {settings.map((setting) => (
              <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                <Typography component={Link} to={setting.to} sx={{ textDecoration: 'none', color: 'inherit' }}>{setting.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {userInfo ? `Hi, ${userInfo.name}` : "Welcome"}
          </Typography>
          {!loggedInUser && <Button variant="contained" onClick={() => navigate("/login")}>Login</Button>}
        </Stack>

        {/* Cart and Wishlist Icons */}
        <Stack direction="row" alignItems="center" spacing={3}>
          <Badge badgeContent={cartItems?.length || 0} color="error">
            <IconButton component={Link} to="/cart">
              <ShoppingCartOutlinedIcon />
            </IconButton>
          </Badge>
          <Badge badgeContent={wishlistItems?.length || 0} color="error">
            <IconButton component={Link} to="/wishlist">
              <FavoriteBorderIcon />
            </IconButton>
          </Badge>
          {isProductList && (
            <IconButton onClick={handleToggleFilters}>
              <TuneIcon color={isProductFilterOpen ? "primary" : "inherit"} />
            </IconButton>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

