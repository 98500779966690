import { Avatar, Button, Paper, Stack, Typography, useTheme, TextField, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo, updateUserAvatarAsync } from '../UserSlice'; // Ensure this action exists in your slice
import { addAddressAsync, resetAddressAddStatus, resetAddressDeleteStatus, resetAddressUpdateStatus, selectAddressAddStatus, selectAddressDeleteStatus, selectAddressStatus, selectAddressUpdateStatus, selectAddresses } from '../../address/AddressSlice';
import { Address } from '../../address/components/Address';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

export const UserProfile = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const status = useSelector(selectAddressStatus);
    const userInfo = useSelector(selectUserInfo);
    const addresses = useSelector(selectAddresses);
    const theme = useTheme();
    const [addAddress, setAddAddress] = useState(false);
    const [avatar, setAvatar] = useState(null); // State for avatar file
    const [avatarPreview, setAvatarPreview] = useState(userInfo?.avatar || ''); // Avatar preview URL

    const addressAddStatus = useSelector(selectAddressAddStatus);
    const addressUpdateStatus = useSelector(selectAddressUpdateStatus);
    const addressDeleteStatus = useSelector(selectAddressDeleteStatus);

    const is900 = useMediaQuery(theme.breakpoints.down(900));
    const is480 = useMediaQuery(theme.breakpoints.down(480));

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, []);

    useEffect(() => {
        if (addressAddStatus === 'fulfilled') {
            toast.success("Address added");
        } else if (addressAddStatus === 'rejected') {
            toast.error("Error adding address, please try again later");
        }
    }, [addressAddStatus]);

    useEffect(() => {
        if (addressUpdateStatus === 'fulfilled') {
            toast.success("Address updated");
        } else if (addressUpdateStatus === 'rejected') {
            toast.error("Error updating address, please try again later");
        }
    }, [addressUpdateStatus]);

    useEffect(() => {
        if (addressDeleteStatus === 'fulfilled') {
            toast.success("Address deleted");
        } else if (addressDeleteStatus === 'rejected') {
            toast.error("Error deleting address, please try again later");
        }
    }, [addressDeleteStatus]);

    useEffect(() => {
        console.log(userInfo);
    }, [userInfo])

    useEffect(() => {

        return () => {
            dispatch(resetAddressAddStatus());
            dispatch(resetAddressUpdateStatus());
            dispatch(resetAddressDeleteStatus());
        };
    }, []);

    const handleAddAddress = (data) => {
        const address = { ...data, user: userInfo._id };
        dispatch(addAddressAsync(address));
        setAddAddress(false);
        reset();
    };

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAvatar(file);
            setAvatarPreview(URL.createObjectURL(file)); // Preview the selected image
        }
    };

    const handleAvatarUpload = async () => {
        if (avatar) {
            const formData = new FormData();
            formData.append('avatar', avatar);

            // Assuming you have a backend API endpoint to handle the avatar upload
            dispatch(updateUserAvatarAsync({ userId: userInfo._id, formData }));
            setAvatar(null);
        }
    };

    return (
        <Stack height={'calc(100vh - 4rem)'} justifyContent={'flex-start'} alignItems={'center'}>

            <Stack component={is480 ? '' : Paper} elevation={1} width={is900 ? '100%' : "50rem"} p={2} mt={is480 ? 0 : 5} rowGap={2}>

                {/* user details - [name ,email ] */}
                <Stack bgcolor={theme.palette.primary.light} color={theme.palette.primary.main} p={2} rowGap={1} borderRadius={'.6rem'} justifyContent={'center'} alignItems={'center'}>
                    <Avatar src={avatarPreview ? avatarPreview : userInfo?.avatar} alt={userInfo?.name} sx={{ width: 70, height: 70 }} />
                    <Typography>{userInfo?.name}</Typography>
                    <Typography>{userInfo?.email}</Typography>

                    {/* Avatar Upload */}
                    <Stack mt={2} alignItems={'center'} rowGap={1}>
                        <Button variant="contained" component="label">
                            Upload Avatar
                            <input hidden accept="image/*" type="file" onChange={handleAvatarChange} />
                        </Button>
                        {avatar && (
                            <LoadingButton loading={status === 'pending'} onClick={handleAvatarUpload} variant="contained">
                                Save Avatar
                            </LoadingButton>
                        )}
                    </Stack>
                </Stack>

                {/* Address section */}
                <Stack justifyContent={'center'} alignItems={'center'} rowGap={3}>
                    {/* heading and add button */}
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} columnGap={1}>
                        <Typography variant='h6' fontWeight={400}>Manage addresses</Typography>
                        <Button onClick={() => setAddAddress(true)} size={is480 ? 'small' : ""} variant='contained'>Add</Button>
                    </Stack>

                    {/* add address form */}
                    {addAddress && (
                        <Stack width={'100%'} component={'form'} noValidate onSubmit={handleSubmit(handleAddAddress)} rowGap={2}>
                            {/* Form fields */}
                            <Stack>
                                <Typography gutterBottom>Type</Typography>
                                <TextField placeholder='Eg. Home, Business' {...register("type", { required: true })} />
                                {errors.type && <Typography color="error">Type is required</Typography>}
                            </Stack>

                            <Stack>
                                <Typography gutterBottom>Street</Typography>
                                <TextField {...register("street", { required: true })} />
                                {errors.street && <Typography color="error">Street is required</Typography>}
                            </Stack>

                            <Stack>
                                <Typography gutterBottom>Postal Code</Typography>
                                <TextField type='number' {...register("postalCode", { required: true })} />
                                {errors.postalCode && <Typography color="error">Postal Code is required</Typography>}
                            </Stack>

                            <Stack>
                                <Typography gutterBottom>Country</Typography>
                                <TextField {...register("country", { required: true })} />
                                {errors.country && <Typography color="error">Country is required</Typography>}
                            </Stack>

                            <Stack>
                                <Typography gutterBottom>Phone Number</Typography>
                                <TextField type='number' {...register("phoneNumber", { required: true })} />
                                {errors.phoneNumber && <Typography color="error">Phone Number is required</Typography>}
                            </Stack>

                            <Stack>
                                <Typography gutterBottom>State</Typography>
                                <TextField {...register("state", { required: true })} />
                                {errors.state && <Typography color="error">State is required</Typography>}
                            </Stack>

                            <Stack>
                                <Typography gutterBottom>City</Typography>
                                <TextField {...register("city", { required: true })} />
                                {errors.city && <Typography color="error">City is required</Typography>}
                            </Stack>

                            <Stack flexDirection={'row'} alignSelf={'flex-end'} columnGap={is480 ? 1 : 2}>
                                <LoadingButton loading={status === 'pending'} type='submit' size={is480 ? "small" : ""} variant='contained'>Add</LoadingButton>
                                <Button color='error' onClick={() => setAddAddress(false)} variant={is480 ? "outlined" : "text"} size={is480 ? "small" : ""}>Cancel</Button>
                            </Stack>
                        </Stack>
                    )}

                    {/* List of addresses */}
                    <Stack width={'100%'} rowGap={2}>
                        {addresses.length > 0 ? (
                            addresses.map((address) => (
                                <Address key={address._id} id={address._id} city={address.city} country={address.country} phoneNumber={address.phoneNumber} postalCode={address.postalCode} state={address.state} street={address.street} type={address.type} />
                            ))
                        ) : (
                            <Typography textAlign={'center'} mt={2} variant='body2'>You have no added addresses</Typography>
                        )}
                    </Stack>
                </Stack>

            </Stack>

        </Stack>
    );
}
