import { FormHelperText, Paper, Stack, Typography, useMediaQuery, useTheme, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
import { selectWishlistItems } from '../../wishlist/WishlistSlice';
import { selectLoggedInUser } from '../../auth/AuthSlice';
import { addToCartAsync, selectCartItems } from '../../cart/CartSlice';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

export const ProductCard = ({ id, title, price, size, thumbnail, brand, stockQuantity, handleAddRemoveFromWishlist, isWishlistCard, isAdminCard, discountPercentage, category }) => {
    const [selectedSize, setSelectedSize] = useState(null)
    const navigate = useNavigate();
    const wishlistItems = useSelector(selectWishlistItems);
    const loggedInUser = useSelector(selectLoggedInUser);
    const cartItems = useSelector(selectCartItems);
    const dispatch = useDispatch();
    let isProductAlreadyinWishlist = -1;

    const theme = useTheme();
    const is1410 = useMediaQuery(theme.breakpoints.down(1410));
    const is932 = useMediaQuery(theme.breakpoints.down(932));
    const is752 = useMediaQuery(theme.breakpoints.down(752));
    const is500 = useMediaQuery(theme.breakpoints.down(500));
    const is608 = useMediaQuery(theme.breakpoints.down(608));
    const is488 = useMediaQuery(theme.breakpoints.down(488));
    const is408 = useMediaQuery(theme.breakpoints.down(408));

    isProductAlreadyinWishlist = wishlistItems.some((item) => item.product._id === id);
    const isProductAlreadyInCart = cartItems.some((item) => item.product._id === id);

    const handleAddToCart = async (e, cat) => {
        e.stopPropagation();
        if (!loggedInUser) {
            navigate('/login');
        } else {
            if (selectedSize) {
                const data = { user: loggedInUser._id, product: id, size: selectedSize };
                dispatch(addToCartAsync(data));
            } if (cat !== "clothing") {
                console.log(cat);
                const data = { user: loggedInUser._id, product: id, category: cat };
                dispatch(addToCartAsync(data));
            }
            else {
                toast.error("Select the size");
            }
        }
    };
    const handleSizeSelect = (size) => {
        setSelectedSize(size)
        console.log(size);
    }

    useEffect(() => console.log(size), [size])

    return (
        <>
            {/* <Helmet>
                <title>{title ? `${title} - Product Details` : 'Product Details'}</title>
                <meta
                    name="description"
                    content={title ? `Buy ${title} for just ₹${price}` : 'Fetching product details...'}
                />
                {title && (
                    <meta name="keywords" content={`Buy ${title}, ${brand}, online shopping`} />
                )}
            </Helmet> */}
            {isProductAlreadyinWishlist !== -1 ? (

                <Stack
                    whileHover={{ scale: 1.030 }}
                    whileTap={{ scale: 1 }}
                    component={isAdminCard ? "" : isWishlistCard ? "" : is408 ? '' : Paper}
                    mt={is408 ? 2 : 0}
                    elevation={1}
                    p={2}
                    width={is408 ? 'auto' : is488 ? "200px" : is608 ? "240px" : is752 ? "300px" : is932 ? '240px' : is1410 ? '300px' : '340px'}
                    sx={{ cursor: "pointer", position: "relative" }}
                    onClick={() => navigate(`/product-details/${id}`)}
                >

                    {/* Discount Label */}
                    {discountPercentage && (
                        <Stack
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                backgroundColor: '#ff6347',
                                color: 'white',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                fontSize: '0.8rem',
                                zIndex: 10
                            }}
                        >
                            {discountPercentage ? Math.round(((discountPercentage - price) / discountPercentage) * 100) : 0}% OFF
                        </Stack>
                    )}

                    {/* Image display */}
                    <Stack>
                        <img
                            width={'100%'}
                            style={{ aspectRatio: 1 / 1, objectFit: "contain" }}
                            height={'100%'}
                            src={thumbnail}
                            alt={`${title} photo unavailable`}
                        />
                    </Stack>

                    {/* Lower section */}
                    <Stack flex={2} justifyContent={'flex-end'} spacing={1} rowGap={2}>
                        <Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant='h6' fontWeight={400}>{title}</Typography>
                                {!isAdminCard && (
                                    <motion.div
                                        whileHover={{ scale: 1.3, y: -10, zIndex: 100 }}
                                        whileTap={{ scale: 1 }}
                                        transition={{ duration: .4, type: "spring" }}
                                    >
                                        <Checkbox
                                            onClick={(e) => e.stopPropagation()}
                                            checked={isProductAlreadyinWishlist}
                                            onChange={(e) => handleAddRemoveFromWishlist(e, id)}
                                            icon={<FavoriteBorder />}
                                            checkedIcon={<Favorite sx={{ color: 'red' }} />}
                                        />
                                    </motion.div>
                                )}
                            </Stack>
                            <Typography color={"text.secondary"}>{brand}</Typography>
                        </Stack>

                        {size && size.length == 0 ? null : <Stack flexDirection="row" flexWrap={'wrap'} alignItems="center" display="block">
                            {size && <Typography>Size: </Typography>}
                            <Stack flexDirection="row" flexWrap={'wrap'} columnGap={"1rem"}>

                                {size && size[0]?.split(',').map((size) => (
                                    <motion.div
                                        key={size}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleSizeSelect(size);
                                        }}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 1 }}
                                        style={{
                                            border: selectedSize === size ? 'none' : '1px solid grayText',
                                            borderRadius: "8px",
                                            width: "fit-content",
                                            height: "30px",
                                            display: "flex",
                                            margin: ".2rem",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            padding: "1.2rem",
                                            backgroundColor: selectedSize === size ? "#DB4444" : "whitesmoke",
                                            color: selectedSize === size ? "white" : "inherit",
                                        }}
                                    >
                                        <p>{size}</p>
                                    </motion.div>
                                ))}
                            </Stack>
                        </Stack>
                        }
                        {/* Price and Add to Cart */}
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography color="secondary" fontWeight={"Bold"} >₹ {price}</Typography>
                            <Typography style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                                ₹ {parseInt(discountPercentage)}
                            </Typography>
                            {!isWishlistCard && !isProductAlreadyInCart && !isAdminCard && (
                                <motion.button
                                    whileHover={{ scale: 1.030 }}
                                    whileTap={{ scale: 1 }}
                                    onClick={(e) => handleAddToCart(e, category.name)}
                                    style={{
                                        padding: "10px 15px",
                                        borderRadius: "3px",
                                        outline: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        backgroundColor: "black",
                                        color: "white",
                                        fontSize: is408 ? '.9rem' : is488 ? '.7rem' : is500 ? '.8rem' : '.9rem',
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", columnGap: ".5rem" }}>
                                        <p>Add To Cart</p>
                                    </div>
                                </motion.button>
                            )}
                        </Stack>
                        {stockQuantity <= 20 && (
                            <FormHelperText sx={{ fontSize: ".9rem" }} error>
                                {stockQuantity === 1 ? "Only 1 stock is left" : "Only a few are left"}
                            </FormHelperText>
                        )}
                    </Stack>
                </Stack >
            ) : ''}
        </>
    );
};
