import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductAsync, resetProductAddStatus, selectProductAddStatus } from '../../products/ProductSlice';
import { Button, FormControl, FormGroup, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme, FormHelperText, FormControlLabel, Checkbox, Box } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import { selectBrands } from '../../brands/BrandSlice';
import { selectCategories } from '../../categories/CategoriesSlice';
import { toast } from 'react-toastify';

const colorOptions = ["Beige", "Black", "Blue", "Brown", "Dark Blue", "Dark Green", "Gold", "Green", "Grey", "Light Blue", "Light Green", "Maroon", "Multicolor", "Orange", "Pink", "Purple", "Red", "Silver", "White", "Yellow"];
const typeOptions = ["Blazer Fabric", "Blouse Material", "Chudidar Fabric", "Gown/Anarkali Kurta & Bottom Material", "Jacket & Shirt Fabric", "Jacket Fabric", "Kurta & Churidar Material", "Kurta & Palazzo Material", "Kurta & Patiyala Material", "Kurta & Sharara Fabric", "Kurta Fabric", "Kurta with Jacket & Bottom Material", "Lace", "Legging Fabric", "Multi-purpose Fabric", "Multipurpose Running Fabric", "Safari Fabric", "Salwar Suit Material", "Salwar and Dupatta Material", "Sherwani Fabric", "Shirt & Trouser Fabric", "Shirt Fabric", "Suit Fabric", "Swimwear/Sportswear Fabric", "Trouser Fabric"];
const fabricOptions = ["Acrylic Blend", "Art Silk", "Brocade", "Chanderi", "Chiffon", "Corduroy", "Cotton Blend", "Cotton Linen", "Cotton Lycra", "Cotton Silk", "Crepe", "Denim", "Dupion Silk", "Georgette", "Hemp", "Jacquard", "Jute", "Khadi Cotton", "Khadi Silk", "Leather", "Linen", "Linen Blend", "Liva", "Lycra Blend", "Lyocell", "Modal", "Muslin", "Net/Lace", "Nylon", "Organza", "Polycotton", "Polyester", "Pure Cotton", "Pure Silk", "Satin", "Silk Blend", "Tussar Silk", "Velvet", "Viscose Rayon", "Wool"];
const idealForOptions = ["Baby Boys", "Baby Boys & Baby Girls", "Baby Girls", "Boys", "Boys & Girls", "Girls", "Men", "Women"];
const patternOptions = ["Animal Print", "Checkered", "Chevron/Zig Zag", "Colorblock", "Dyed", "Embellished", "Embroidered", "Floral Print", "Geometric Print", "Graphic Print", "Paisley", "Polka Print", "Printed", "Self Design", "Solid", "Striped", "Washed/Ombre"];
const topLengthOptions = ["0.8 m", "1 m", "1.2 m", "1.6 m", "1.70 m", "1.80 m", "2 m", "2.10m", "2.2 m", "2.25 m", "2.35 m", "2.5 m", "3 m", "3.35 m", "3.50 m", "4 m", "5 m", "6 m"];
const occasionOptions = ["Casual", "Formal", "Party & Festive", "Wedding"];
const fabricCareOptions = ["Cold water wash only", "Do not Iron on print/embroidery/embellishment", "Do not bleach", "Do not dry clean", "Do not iron", "Do not steam iron", "Do not tumble dry", "Do not wring", "Dry clean only", "Dry flat", "Dry in shade", "First wash dry clean thereafter handwash", "Gentle Machine Wash", "Hand wash", "Machine wash as per tag", "Regular Machine Wash", "Reverse and dry", "Reverse and iron", "Slight color may bleed in all washes", "Slight color may bleed in first wash", "Slight shrinkage would be expected", "Wash with like colors"];
const bottomLengthOptions = ["1.2 m", "1.25m", "1.3 m", "1.5 m", "2 m", "2.20m", "2.35 m", "2.5 m", "2.6 m", "3 m", "3.5 m", "4 m", "5 m", "NA"];
const dupattaLengthOptions = ["2 m - 2.5 m", "2.10m", "2.2 m", "2.36 m", "2.5 m", "2.6 m - 3 m", "3 m", "NA"];
const stitchingTypeOptions = ["Semi Stitched", "Unstitched", "NA"];
const fabricPurityOptions = ["Blend", "Pure", "Synthetic"];
const ornamentationTypeOptions = ["Aari Work", "Applique", "Beads & Stones", "Button", "Chikankari", "Cut Work", "Gotta Patti", "Knots and Tie-ups", "Lace", "Mirror Work", "NA", "Patch Work", "Phulkari", "Piping Detail", "Pom Pom/Bow", "Ruffle", "Schiffli", "Sequins", "Tassel", "Thread Work", "Zardozi Kantha", "Zari"];
const regionalSpecialityOptions = ["Baluchari", "Bandhani", "Bandhej", "Bhagalpuri", "Chanderi", "Hand Batik", "Ikkat", "Jamdani", "Kantha", "Katha", "Kota Doria", "Leheria", "Lucknow Chikankari", "Madhubani", "Maheshwari", "Phulkari", "Rajshahi"];
const patternPrintTypeOptions = ["Abstract", "Ajrakh", "Animal Print", "Argyle", "Batik", "Block Print", "Botanical Prints", "Camouflage", "Checkered", "Chevron/Zig Zag", "Colorblock", "Conversational", "Digital Prints", "Ditsy Print", "Embellished", "Ethnic Motifs", "Floral", "Foil Print", "Geometric", "Gold Print", "Graphic", "Heavy Washed", "Herringbone", "Houndstooth", "Human Figures", "Ikkat", "Kalamkari", "Leheriya", "Light Washed", "Mid Washed", "Ombre", "Paisley", "Polka", "Quirky", "Self Design", "Sequined", "Shibori", "Solid", "Striped", "Tie & Dye", "Tribal", "Typography", "Varsity", "Warli", "Woven Design"];
const trendOptions = ["Botanical print", "Contemporary collection", "Embroidered Neckline", "Heavy Kali Work", "Placement Floral", "Printed Neckline", "Sequin embroidered", "Summer Colors", "Traditional collection"];
const secondaryColorOptions = ["Beige", "Black", "Blue", "Brown", "Dark Blue", "Dark Green", "Gold", "Green", "Grey", "Light Blue", "Light Green", "Maroon", "Multicolor", "Orange", "Pink", "Purple", "Red", "Silver", "White", "Yellow"];

const availableSizess = [
    // Infant and toddler sizes
    { _id: '1', name: '0 - 3 Months' },
    { _id: '2', name: '0 - 6 Months' },
    { _id: '3', name: '3 - 6 Months' },
    { _id: '4', name: '6 - 9 Months' },
    { _id: '5', name: '9 - 12 Months' },
    { _id: '6', name: '12 - 18 Months' },
    { _id: '7', name: '18 - 24 Months' },
    { _id: '8', name: '1 - 2 Years' },
    { _id: '9', name: '2 - 3 Years' },
    { _id: '10', name: '3 - 4 Years' },
    { _id: '11', name: '4 - 5 Years' },
    { _id: '12', name: '5 - 6 Years' },
    { _id: '13', name: '6 - 7 Years' },
    { _id: '14', name: '7 - 8 Years' },
    { _id: '15', name: '8 - 9 Years' },
    { _id: '16', name: '9 - 10 Years' },
    { _id: '17', name: '10 - 11 Years' },
    { _id: '18', name: '11 - 12 Years' },
    { _id: '19', name: '12 - 13 Years' },
    { _id: '20', name: '13 - 14 Years' },
    { _id: '21', name: '14 - 15 Years' },
    { _id: '22', name: '15 - 16 Years' },

    // Adult sizes (regular and plus)
    { _id: '23', name: '3XS' },
    { _id: '24', name: 'XXS' },
    { _id: '25', name: 'XS' },
    { _id: '26', name: 'S' },
    { _id: '27', name: 'M' },
    { _id: '28', name: 'L' },
    { _id: '29', name: 'XL' },
    { _id: '30', name: 'XXL' },
    { _id: '31', name: '3XL' },
    { _id: '32', name: '4XL' },
    { _id: '33', name: '5XL' },
    { _id: '34', name: '6XL' },
    { _id: '35', name: '7XL' },
    { _id: '36', name: '8XL' },
    { _id: '37', name: '9XL' },
    { _id: '38', name: '10XL' },
];


export const AddProduct = () => {
    const { register, handleSubmit, control, reset, formState: { errors }, setError, clearErrors } = useForm({
        defaultValues: {
            availableSizes: []
        }
    });
    const [images, setImages] = useState([]); // Track images selected by the user
    const [flag, setFlag] = useState(false)
    const [cat, setCat] = useState(null);
    const dispatch = useDispatch();
    const brands = useSelector(selectBrands);
    const categories = useSelector(selectCategories);
    const productAddStatus = useSelector(selectProductAddStatus);
    const navigate = useNavigate();
    const theme = useTheme();
    const is1100 = useMediaQuery(theme.breakpoints.down(1100));
    const is480 = useMediaQuery(theme.breakpoints.down(480));

    useEffect(() => {
        if (productAddStatus === 'fullfilled') {
            setFlag(false)
            reset();
            toast.success("New product added");
            navigate("/admin/dashboard");
        } else if (productAddStatus === 'rejected') {
            setFlag(false)
            toast.error("Error adding product, please try again later");
        }
    }, [productAddStatus]);

    useEffect(() => {
        return () => {
            dispatch(resetProductAddStatus());
        };
    }, [dispatch]);

    // Custom function to handle image file selection
    const handleImageChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setImages(selectedFiles);

        // Check if there are exactly 4 images selected
        if (selectedFiles.length !== 4) {

            setError("images", { type: "manual", message: "You must upload exactly 4 product images." });
        } else {
            clearErrors("images");
        }
    };

    const handleAddProduct = (data) => {
        // Ensure the user uploaded exactly 4 images before proceeding
        const newProduct = {
            SKUID: data.SKUID,
            title: data.title,
            category: data.category,
            brand: data.brand,
            availableSizes: data.availableSizes,
            description: data.description,
            price: data.price,
            discountPercentage: data.discountPercentage,
            ShippingCharge: data.ShippingCharge,
            Weight: data.Weight,
            stockQuantity: data.stockQuantity,
            images: [data.image0, data.image1, data.image2, data.image3], // Images links
            thumbnail: data.thumbnail, // Thumbnail link
        };

        // Add conditional fields for "clothing" category
        if (data.category === "clothing") {
            newProduct.color = data.color;
            newProduct.type = data.type;
            newProduct.fabric = data.fabric;
            newProduct.pattern = data.pattern;
            newProduct.idealFor = data.idealFor;
            newProduct.topLength = data.topLength;
            newProduct.occasion = data.occasion;
            newProduct.fabricCare = data.fabricCare;
            newProduct.bottomLength = data.bottomLength;
            newProduct.dupattaLength = data.dupattaLength;
            newProduct.stitchingType = data.stitchingType;
            newProduct.fabricPurity = data.fabricPurity;
            newProduct.ornamentationType = data.ornamentationType;
            newProduct.regionalSpeciality = data.regionalSpeciality;
            newProduct.patternPrintType = data.patternPrintType;
            newProduct.trend = data.trend;
            newProduct.secondaryColor = data.secondaryColor;
        }

        // Remove unused fields from the data
        delete data.image0;
        delete data.image1;
        delete data.image2;
        delete data.image3;

        setFlag(true);
        dispatch(addProductAsync(newProduct)); // Dispatch the JSON object
    };


    return (
        <Stack p={'0 16px'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
            <Stack width={is1100 ? "100%" : "60rem"} rowGap={4} mt={is480 ? 4 : 6} mb={6} component={'form'} noValidate onSubmit={handleSubmit(handleAddProduct)}>
                {/* field area */}
                <Stack rowGap={3}>
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>SKU ID</Typography>
                        <TextField {...register("SKUID", { required: 'SKUID is required' })} />
                        {errors.SKUID && (
                            <FormHelperText error>{errors.SKUID.message}</FormHelperText>
                        )}
                    </Stack>
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Title</Typography>
                        <TextField {...register("title", { required: 'Title is required' })} />
                        {errors.title && (
                            <FormHelperText error>{errors.title.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack flexDirection={'row'}>
                        <FormControl fullWidth>
                            <InputLabel id="category-selection">Category</InputLabel>
                            <Select {...register("category", { required: "Category is required" })} onChange={(e) => {
                                const selectedCat = e.target.value;
                                const catname = categories.find((cat) => cat._id === selectedCat);

                                if (catname) {
                                    setCat(catname.name);
                                    console.log(catname);
                                }
                            }} labelId="category-selection" label="Category">
                                {categories.map((category) => (
                                    <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                            {errors.category && (
                                <FormHelperText error>{errors.category.message}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="brand-selection">Brand</InputLabel>
                            <Select {...register("brand", { required: "Brand is required" })} labelId="brand-selection" label="Brand">
                                {brands.map((brand) => (
                                    <MenuItem key={brand._id} value={brand._id}>{brand.name}</MenuItem>
                                ))}
                            </Select>
                            {errors.brand && (
                                <FormHelperText error>{errors.brand.message}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>

                    {cat === "clothing" && (
                        <Stack flexDirection={'row'} flexWrap="wrap" gap={2}>
                            {/* Color Selection */}
                            <FormControl fullWidth>
                                <InputLabel id="color-selection">Color</InputLabel>
                                <Select {...register("color", { required: "Color is required" })} labelId="color-selection" label="Color">
                                    {colorOptions.map((color) => (
                                        <MenuItem key={color} value={color}>{color}</MenuItem>
                                    ))}
                                </Select>
                                {errors.color && (
                                    <FormHelperText error>{errors.color.message}</FormHelperText>
                                )}
                            </FormControl>

                            {/* Type Selection */}
                            <FormControl fullWidth>
                                <InputLabel id="type-selection">Type</InputLabel>
                                <Select {...register("type", { required: "Type is required" })} labelId="type-selection" label="Type">
                                    {typeOptions.map((type) => (
                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                    ))}
                                </Select>
                                {errors.type && (
                                    <FormHelperText error>{errors.type.message}</FormHelperText>
                                )}
                            </FormControl>

                            {/* Fabric Selection */}
                            <FormControl fullWidth>
                                <InputLabel id="fabric-selection">Fabric</InputLabel>
                                <Select {...register("fabric", { required: "Fabric is required" })} labelId="fabric-selection" label="Fabric">
                                    {fabricOptions.map((fabric) => (
                                        <MenuItem key={fabric} value={fabric}>{fabric}</MenuItem>
                                    ))}
                                </Select>
                                {errors.fabric && (
                                    <FormHelperText error>{errors.fabric.message}</FormHelperText>
                                )}
                            </FormControl>

                            {/* Pattern Selection */}
                            <FormControl fullWidth>
                                <InputLabel id="pattern-selection">Pattern</InputLabel>
                                <Select {...register("pattern", { required: "Pattern is required" })} labelId="pattern-selection" label="Pattern">
                                    {patternOptions.map((pattern) => (
                                        <MenuItem key={pattern} value={pattern}>{pattern}</MenuItem>
                                    ))}
                                </Select>
                                {errors.pattern && (
                                    <FormHelperText error>{errors.pattern.message}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="idealFor-selection">Ideal For</InputLabel>
                                <Select {...register("idealFor", { required: "Ideal For is required" })} labelId="idealFor-selection" label="Ideal For">
                                    {idealForOptions.map((idealFor) => (
                                        <MenuItem key={idealFor} value={idealFor}>{idealFor}</MenuItem>
                                    ))}
                                </Select>
                                {errors.idealFor && (
                                    <FormHelperText error>{errors.idealFor.message}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="topLength-selection">Top Length</InputLabel>
                                <Select {...register("topLength", { required: "Top Length is required" })} labelId="topLength-selection" label="Top Length">
                                    {topLengthOptions.map((length) => (
                                        <MenuItem key={length} value={length}>{length}</MenuItem>
                                    ))}
                                </Select>
                                {errors.topLength && (
                                    <FormHelperText error>{errors.topLength.message}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="occasion-selection">Occasion</InputLabel>
                                <Select {...register("occasion", { required: "Occasion is required" })} labelId="occasion-selection" label="Occasion">
                                    {occasionOptions.map((occasion) => (
                                        <MenuItem key={occasion} value={occasion}>{occasion}</MenuItem>
                                    ))}
                                </Select>
                                {errors.occasion && (
                                    <FormHelperText error>{errors.occasion.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="fabricCare-selection">Fabric Care</InputLabel>
                                <Select {...register("fabricCare", { required: "Fabric Care is required" })} labelId="fabricCare-selection" label="Fabric Care">
                                    {fabricCareOptions.map((care) => (
                                        <MenuItem key={care} value={care}>{care}</MenuItem>
                                    ))}
                                </Select>
                                {errors.fabricCare && (
                                    <FormHelperText error>{errors.fabricCare.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="bottomLength-selection">Bottom Length</InputLabel>
                                <Select {...register("bottomLength", { required: "Bottom Length is required" })} labelId="bottomLength-selection" label="Bottom Length">
                                    {bottomLengthOptions.map((length) => (
                                        <MenuItem key={length} value={length}>{length}</MenuItem>
                                    ))}
                                </Select>
                                {errors.bottomLength && (
                                    <FormHelperText error>{errors.bottomLength.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="dupattaLength-selection">Dupatta Length</InputLabel>
                                <Select {...register("dupattaLength", { required: "Dupatta Length is required" })} labelId="dupattaLength-selection" label="Dupatta Length">
                                    {dupattaLengthOptions.map((length) => (
                                        <MenuItem key={length} value={length}>{length}</MenuItem>
                                    ))}
                                </Select>
                                {errors.dupattaLength && (
                                    <FormHelperText error>{errors.dupattaLength.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="stitchingType-selection">Stitching Type</InputLabel>
                                <Select {...register("stitchingType", { required: "Stitching Type is required" })} labelId="stitchingType-selection" label="Stitching Type">
                                    {stitchingTypeOptions.map((type) => (
                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                    ))}
                                </Select>
                                {errors.stitchingType && (
                                    <FormHelperText error>{errors.stitchingType.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="fabricPurity-selection">Fabric Purity</InputLabel>
                                <Select {...register("fabricPurity", { required: "Fabric Purity is required" })} labelId="fabricPurity-selection" label="Fabric Purity">
                                    {fabricPurityOptions.map((purity) => (
                                        <MenuItem key={purity} value={purity}>{purity}</MenuItem>
                                    ))}
                                </Select>
                                {errors.fabricPurity && (
                                    <FormHelperText error>{errors.fabricPurity.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="ornamentationType-selection">Ornamentation Type</InputLabel>
                                <Select {...register("ornamentationType", { required: "Ornamentation Type is required" })} labelId="ornamentationType-selection" label="Ornamentation Type">
                                    {ornamentationTypeOptions.map((type) => (
                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                    ))}
                                </Select>
                                {errors.ornamentationType && (
                                    <FormHelperText error>{errors.ornamentationType.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="regionalSpeciality-selection">Regional Speciality</InputLabel>
                                <Select {...register("regionalSpeciality", { required: "Regional Speciality is required" })} labelId="regionalSpeciality-selection" label="Regional Speciality">
                                    {regionalSpecialityOptions.map((speciality) => (
                                        <MenuItem key={speciality} value={speciality}>{speciality}</MenuItem>
                                    ))}
                                </Select>
                                {errors.regionalSpeciality && (
                                    <FormHelperText error>{errors.regionalSpeciality.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="patternPrintType-selection">Pattern Print Type</InputLabel>
                                <Select {...register("patternPrintType", { required: "Pattern Print Type is required" })} labelId="patternPrintType-selection" label="Pattern Print Type">
                                    {patternPrintTypeOptions.map((type) => (
                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                    ))}
                                </Select>
                                {errors.patternPrintType && (
                                    <FormHelperText error>{errors.patternPrintType.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="trend-selection">Trend</InputLabel>
                                <Select {...register("trend", { required: "Trend is required" })} labelId="trend-selection" label="Trend">
                                    {trendOptions.map((trend) => (
                                        <MenuItem key={trend} value={trend}>{trend}</MenuItem>
                                    ))}
                                </Select>
                                {errors.trend && (
                                    <FormHelperText error>{errors.trend.message}</FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="secondaryColor-selection">Secondary Color</InputLabel>
                                <Select {...register("secondaryColor", { required: "Secondary Color is required" })} labelId="secondaryColor-selection" label="Secondary Color">
                                    {secondaryColorOptions.map((color) => (
                                        <MenuItem key={color} value={color}>{color}</MenuItem>
                                    ))}
                                </Select>
                                {errors.secondaryColor && (
                                    <FormHelperText error>{errors.secondaryColor.message}</FormHelperText>
                                )}
                            </FormControl>


                        </Stack>
                    )}

                    {/* Other fields */}
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Description</Typography>
                        <TextField multiline rows={4} {...register("description", { required: 'Description is required' })} />
                        {errors.description && (
                            <FormHelperText error>{errors.description.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Price</Typography>
                        <TextField type='number' {...register("price", { required: 'Price is required' })} />
                        {errors.price && (
                            <FormHelperText error>{errors.price.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>MRP : </Typography>
                        <TextField type='number' {...register("discountPercentage", { required: 'Discount Percentage is required' })} />
                        {errors.discountPercentage && (
                            <FormHelperText error>{errors.discountPercentage.message}</FormHelperText>
                        )}
                    </Stack>
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Shipping Charge</Typography>
                        <TextField type='number' {...register("ShippingCharge", { required: 'ShippingCharge is required' })} />
                        {errors.ShippingCharge && (
                            <FormHelperText error>{errors.ShippingCharge.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Weight</Typography>
                        <TextField type='number' {...register("Weight", { required: 'Weight is required' })} />
                        {errors.Weight && (
                            <FormHelperText error>{errors.Weight.message}</FormHelperText>
                        )}
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Stock Quantity</Typography>
                        <TextField type='number' {...register("stockQuantity", { required: 'Stock Quantity is required' })} />
                        {errors.stockQuantity && (
                            <FormHelperText error>{errors.stockQuantity.message}</FormHelperText>
                        )}
                    </Stack>
                    {cat == 'clothing' && <Stack fullWidth>
                        <Typography variant="h6">Available Sizes</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
                            {availableSizess.map((size) => (
                                <FormControlLabel
                                    key={size._id}
                                    control={
                                        <Controller
                                            name="availableSizes"
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    value={size.name}
                                                    checked={field.value.includes(size.name)}
                                                    onChange={(e) => {
                                                        const newValue = e.target.checked
                                                            ? [...field.value, size.name]
                                                            : field.value.filter((v) => v !== size.name);
                                                        field.onChange(newValue);
                                                    }}
                                                />
                                            )}
                                        />
                                    }
                                    label={size.name}
                                />
                            ))}
                        </Box>
                        {errors.availableSizes && (
                            <FormHelperText error>{errors.availableSizes.message}</FormHelperText>
                        )}
                    </Stack>}

                    {/* Image Selection */}
                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Thumbnail</Typography>
                        <TextField {...register("thumbnail", { required: "Thumbnail is required" })} />
                    </Stack>

                    <Stack>
                        <Typography variant='h6' fontWeight={400} gutterBottom>Product Images</Typography>

                        <Stack rowGap={2}>

                            <TextField {...register("image0", { required: "Image is required" })} />
                            <TextField {...register("image1", { required: "Image is required" })} />
                            <TextField {...register("image2", { required: "Image is required" })} />
                            <TextField {...register("image3", { required: "Image is required" })} />

                        </Stack>

                    </Stack>
                </Stack>

                {/* Submit button */}
                <Button type='submit' disabled={flag} variant='contained' size='large'>
                    {flag ? "Processing..." : "Add Product"}
                </Button>
            </Stack>
        </Stack>
    );
};
